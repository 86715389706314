<template>
  <div>
    <b-container fluid>
      <b-row class="hero">
        <b-col md="4">
          <img src="@/assets/images/background/iphone.png">
        </b-col>
        <b-col md="2">
        </b-col>
        <b-col md="6" class="text-right mtop-150">
          <h1>عن مخطط</h1>
          <p>  مخطط هو تطبيق عقاري يوفر اسعار الصفقات العقارية والمعلومات العمرانية المتنوعة على الخريطة في مستويات المدن والمخططات وقطع الأراضي، يعمل مخطط على جمع المعلومات من البيانات المفتوحة والمصادر المعتمدة مما يتيح للمستخدم شفافية عالية في اتخاذ القرار الاستثماري.</p>
          <b-button @click="playStore" class="btn-rounded black text-left align-top" size="lg" variant="outline-primary">
            <span class="d-inline-block pb-1 mr-1">
              <img src="@/assets/images/icons/play-store.svg" class="btn-svg">
            </span>
            <span class="d-inline-block">
              <span class="fs-8 btn-intro">GET IT ON</span>
              <span class="d-block">Google Play</span>
            </span>
          </b-button>
          <b-button @click="appStore" class="btn-rounded black text-left align-top" size="lg" variant="outline-primary">
            <span class="d-inline-block pb-1 mr-1">
              <img src="@/assets/images/icons/apple-logo.svg" class="btn-svg">
            </span>
            <span class="d-inline-block">
              <span class="fs-8 btn-intro">GET IT ON</span>
              <span class="d-block">App Store</span>
            </span>
          </b-button>
           <b-button class="btn-rounded black arabic" @click="goToMap" size="lg" variant="outline-primary">ابحث في الخريطة</b-button>
        </b-col>
      </b-row>
    </b-container>
    <section class="section-features" id="section_features">
      <b-container fluid>
        <b-row>
          <b-col md="4">
            <span class="d-block mb-4">
              <img src="@/assets/images/icons/share.svg">
            </span>
            <h3>شــارك</h3>
            <p>تفضيل العقار، أضف ملاحظاتك (بكل خصوصية)، مشاركة المعلومات لأي طرف تريد بكل سهولة.</p>
          </b-col>
          <b-col md="4">
          <span class="d-block mb-4">
            <img src="@/assets/images/icons/compare.svg">
          </span>
            <h3>مقارنة الأسعار</h3>
            <p>مقارنة أسعار الصفقات العقارية المنفذة في الأراضي المختلفة على مستوى المخطط والمدينة.</p>
          </b-col>
          <b-col md="4">
          <span class="d-block mb-4">
            <img src="@/assets/images/icons/view.svg">
          </span>
            <h3>استعراض</h3>
            <p>استعرض كافة المعلومات الأساسية للعقار (اسم الحي، رقم المخطط، رقم القطعة، النوع، الموقع، المساحة، الاضلاع، عرض الشوارع).</p>
          </b-col>
        </b-row>
        <b-row class="divide-features">
          <b-col md="2" class="fill-features">
          </b-col>
          <b-col md="4">
          <span class="d-block mb-4">
            <img src="@/assets/images/icons/map.svg">
          </span>
            <h3>خريطة تفاعلية</h3>
            <p>خريطة تفاعلية سهلة الاستخدام توجد كافة المعلومات العقارية في مكان واحد.</p>
          </b-col>
          <b-col md="4">
          <span class="d-block mb-4">
            <img src="@/assets/images/icons/stats.svg">
          </span>
            <h3>احصائيات موثوقة</h3>
            <p>تعرف على كافة الاحصائيات ومتوسطات الأسعار في مختلف المناطق.</p>
          </b-col>
          <b-col md="2" class="fill-features">
          </b-col>
        </b-row>
      </b-container>
    </section> <!--
    <section class="section-two">
      <b-container fluid>
        <b-row class="section">
          <b-col class="block" md="7">
            <h1>بعض النص هنا</h1>
            <p>بعض النص هنا بعض النص هنا بعض النص هنا بعض النص هنا بعض النص هن
          بعض النص هنا بعض النص هنا بعض النص هنا بعض النص هنا بعض النص هن
          بعض النص هنا بعض النص هنا بعض النص هنا بعض النص هنا بعض النص هن
          </p>
          </b-col>
          <b-col class="image">
            <img src="@/assets/images/background/iphone.png" >
          </b-col>
        </b-row>
        <b-row class="section two">
          <b-col class="image">
            <img src="@/assets/images/background/iphone.png" >
          </b-col>
          <b-col md="7" class="block">
            <h1>العنوان</h1>
            <p>خريطة عقارية تفاعلية توفر مؤشرات الأسعار العقارية والمعلومات العمرانية وغيرها من البيانات عن السوق العقاري السعودي وذلك باستخدام البيانات المفتوحة والذكاء الاصطناعي وذكاء الأعمال، لنوفّر للمستخدم شفافيةً عاليةً عن كافة مكونات القطاع العقاري وتوجهات السو</p>
          </b-col>
        </b-row>
      </b-container>
    </section> -->
    <section class="section-partners">
      <b-container fluid>
        <h2>المصادر</h2>
        <b-row class="mtop-50">
          <b-col sm="4" class="mtop-50">
            <img src="@/assets/images/partners/3.png" class="img-fluid">
          </b-col>
          <b-col sm="4" class="mtop-50">
            <img src="@/assets/images/partners/1.png" class="img-fluid">
          </b-col>
          <b-col sm="4" class="mtop-50">
            <img src="@/assets/images/partners/2.png" class="img-fluid">
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>


<script>

export default {
  title: "مخطط",
  components: {
  },
  methods: {
    goToMap() {
      this.$router.push('/home')
    },
    appStore() {
      location.replace("https://apps.apple.com/us/app/mokhatat/id1536398678")
    },
    playStore() {
      location.replace("https://play.google.com/store/apps/details?id=com.mokhatat")
    }
  }

}
</script>

<style lang="scss">
</style>
